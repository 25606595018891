import React from "react";
import TimeLine from "../../PagesComponents/TimeLine/TimeLine";
import TimeLineEn from "../../PagesComponents/TimeLine/TimeLineEn";

import "react-vertical-timeline-component/style.min.css";
import './History.css'

export default function HistoryEn() {
  return (
    <div className="history-container">
      <section className="history-hero">
        <h2>OUR <br/> HISTORY</h2>
        <h3>OPENS OUR WAY</h3>
      </section>
      <TimeLineEn />
    </div>
  );
}
