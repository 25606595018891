import React from "react";
import Banner from "../../PagesComponents/Banner/Banner";
import ContactForm from "../../PagesComponents/ContactForm/ContactForm";
import { Col, Row, Container } from "react-bootstrap";


import "./Lease.css";

import banner from "../../../assets/leaseBanner.webp";
import banner2 from "../../../assets/leaseBannerInf.webp";
import ImgProduct from "../../../assets/leaseBannerMid.webp";

const Lease = () => {
  return (
    <div className="lease">
      <Banner img={banner} />

      <section className="lease-main">
        <div>
        <p className="title1">Arrenda+ Busines Solutions</p>
          <p className="text1">
            Hemos formado una alianza con una de las arrendadoras de mayor
            prestigio a nivel nacional con una trayectoria consolidada para
            ofrecerles una opción de arrendamiento puro al adquirir nuestro
            producto.
          </p>
          <p className="subtitle1">¿Qué es arrenda+ Busines Solutions?</p>
          <p className="text1">
            Arrenda+ Busines Solutions es una sociedad financiera fundada en
            2006, enfocada en atender principalmente a Pymes y a personas
            físicas con proyectos productivos que permiten impulsar el
            crecimiento de las empresas.
          </p>

          <p><a href="https://www.arrendamas.com/" target="_blank" className="">
            Conoce más de Arrendamás</a>
          </p>

          <p className="subtitle1">¿Cúal es el proceso?</p>
          <p className="text1">
            Contáctanos a través de nuestra página llenando un simple formulario
            para crear una cotización con los productos que necesitas en este
            esquema y conozcas las ventajas de esta solución financiera.
          </p>
          <p className="text1">
            Una vez llenada la cotización, un ejecutivo de Arrenda+ busines
            solutions se pondrá en contacto para poder armar tu proyecto y tomes
            ventaja del arrendamiento puro.
          </p>
        </div>
      </section>

      <Container fluid className="p-3">
        <Row className="px-0 no-gutters">
          <Col sm={12} md={6} lg={6} className="backgroundBeneficios text-light m-0 p-0">
            <Container className="p-5 pt-0">
            <h1 className="text-light title pt-4 p-2">Beneficios</h1>
            
              <Row>
                <Col md={1} className="d-none d-lg-block d-flex align-items-center justify-content-center">
                  <p className="text">
                    <a className="plus">+</a>
                  </p>
                </Col>
                <Col md={11} className="d-flex align-items-center">
                  <p className="text mb-0">
                  <a className="plus d-lg-none d-md-block">+</a> No te descapitalizas, utilizando los recursos de la empresa en otros proyectos.
                  </p>
                </Col>
              </Row>

              <Row>
                <Col md={1} className="d-none d-lg-block d-flex align-items-center justify-content-center">
                  <p className="text">
                    <a className="plus">+</a>
                  </p>
                </Col>
                <p className="d-lg-none d-md-block d-sm-block"></p>
                <Col md={11} className="d-flex align-items-center">
                  <p className="text mb-0">
                  <a className="plus d-lg-none d-md-block">+</a> A través de arrendamiento lo puedes hacer deducible como gasto y no un activo a depreciar.
                  </p>
                </Col>
              </Row>

              <Row>
                <Col md={1} className="d-none d-lg-block d-flex align-items-center justify-content-center">
                  <p className="text">
                    <a className="plus">+</a>
                  </p>
                </Col>
                <p className="d-lg-none d-md-block d-sm-block"></p>
                <Col md={11} className="d-flex align-items-center">
                  <p className="text mb-0">
                  <a className="plus d-lg-none d-md-block">+</a> Productos de alta calidad para oficina con una gran variedad de opciones.
                  </p>
                </Col>
              </Row>

              <Row>
                <Col md={1} className="d-none d-lg-block d-flex align-items-center justify-content-center">
                  <p className="text">
                    <a className="plus">+</a>
                  </p>
                </Col>
                <p className="d-lg-none d-md-block d-sm-block"></p>
                <Col md={11} className="d-flex align-items-center">
                  <p className="text mb-0">
                  <a className="plus d-lg-none d-md-block">+</a> Los proyectos se arman a la conveniencia de los clientes para que se adapte mejor a sus necesidades.
                  </p>
                </Col>
              </Row>
              
            </Container>

          </Col>
          <Col sm={12} md={6} lg={6} className="m-0 p-0">

          <Container className="m-o p-0">
          <img src={ImgProduct} alt="" className="imgLease" />
          </Container>
          
          </Col>
        </Row>
      </Container>

        <ContactForm/>

          <Container className="banner2 p-3">
          <Banner img={banner2} />
          </Container>
    </div>
  );
};

export default Lease;
