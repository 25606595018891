import React from "react";
import BannerHome from "../../PagesComponents/Banner/BannerHome";
import Space from "../../PagesComponents/Space/Space";
import InfoCard from "../../PagesComponents/InfoCard/InfoCard";
import TextBox from "../../PagesComponents/TextBox/TextBox";
import imageMision from "../../../assets/mision.webp";

import brands from "../../../brands.json";
import banner1 from "../../../assets/banner1.webp";
import banner2 from "../../../assets/banner2.webp";
import banner3 from "../../../assets/banner3.webp";
import banner4 from "../../../assets/banner4.webp";

import "./Home.css";

const blog1 =
  "Durante esta expo, hubo una serie de conferencias interesantes, entre ellas, una ofrecida por Cristiano Bertin, un experto en sostenibilidad en los productos para espacios de trabajo, habló sobre la importancia de la sustentabilidad en el diseño de mobiliario y sobre su importancia en la industria del diseño. \r\n\r\nOtro de los momentos destacados de la Expo fue la presentación de la colección “Diagrama” diseñada por el Estudio Claudina Flores en colaboración con Labenze Design. La colección, que incluye productos de origen artesanal, materiales certificados como sustentables y funcionalidad para crear espacios innovadores y atractivos. \r\n\r\nLabenze design también presentó grandes lanzamientos como la colección Kasia, disponible en silla con y sin brazos y banco; silla Hug, que ha recibido múltiples premios: Archiproducts Design Awards 2022, por su gran diseño y Sustainability Award, ya que está fabricada con un material reciclado posconsumo (PCR) regenerado certificado. No podemos dejar de lado el gran diseño de Dress code, Gala Edu y base Atana. \r\n\r\nOtro producto que nos enorgullece mucho haber presentado es Brigida Eco, hecha con piel de nopal, sin pegamento y patas hechas con recuperación de plástico. Todas estas acciones se traducen a una reducción de emisiones y ahorro de agua, obteniendo un producto responsable con el medio ambiente, de alta calidad y durabilidad.";
  const regex = '/(\\n)/g'; (blog1.replaceAll(regex, '<br>'));

export default function Home() {
  return (
    <div>
      <BannerHome />
      <div className="home-container">
        <TextBox
          text1="Mejoramos cualquier espacio a través del mobiliario, despertando la creatividad e inspirando a los usuarios a través del confort, la ergonomía y el diseño."
          color="var(--color-verdeoscuro)"
          colorletter="#FFF"
        />
        <Space
          image={imageMision}
          info="Somos un grupo que brindamos soluciones en mobiliario para espacios de oficina, contract y hospitality. Cada una de nuestras marcas cuenta con una esencia y personalidad propia."
          info2="Contamos con exclusividad de socios comerciales internacionales y más de 250 distribuidores en toda la república mexicana. El catálogo de Grupo Requiez es el más extenso del país, con propuestas en tecnología y diseño para corresponder a la necesidad de los distintos usuarios."
          color="var(--color-beige)"
        />
        <h2 className="home-container_subtitle" id="brands">
          NUESTRAS MARCAS
        </h2>
        <div className="home-container_brands">
          {brands.map(({ id, logo, img, desc, link }) => {
            return (
              <InfoCard
                key={id}
                logo={logo}
                image={img}
                info={desc}
                link={link}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
