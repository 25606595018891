import React from 'react'
import { Outlet } from 'react-router-dom'
import Nav from "../../PagesComponents/Nav/Nav"
import Nav2 from '../../PagesComponents/Nav/Nav2'
import Footer from "../../PagesComponents/Footer/Footer"

export default function Layout() {
  return (
    <div>
        <Nav2 />
        <Outlet />
        <Footer />
    </div>
  )
}
