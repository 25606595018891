import React from 'react'
import { Outlet } from 'react-router-dom'
import Nav from "../../PagesComponents/Nav/Nav"
import Nav2 from '../../PagesComponents/Nav/Nav2'
import FooterEn from "../../PagesComponents/Footer/FooterEn"

export default function Layout() {
  return (
    <div>
        <Nav />

        <Outlet />
        <FooterEn />
    </div>
  )
}
