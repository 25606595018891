import React, { useState } from "react";
import { useFormik } from "formik";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import Banner from "../../PagesComponents/Banner/Banner";
import axios from "axios";

import "./Faq.css";

import banner from "../../../assets/faqs.webp";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Faq = () => {
  return (
    <div className="faq">
      <Banner img={banner} />
      <main>
        <section>
          <h2>FAQS</h2>
          <div>
            <h4>Where can I go to see your products in person?</h4>
            <p>
              We have a large distribution network throughout the country.
              Please visit our website to identify the nearest authorized
              distributor in your area. Postal Code: 45645.
              <br />
              <a href="https://www.requiez.com/distribuidores" target="_blank">
                DISTRIBUTORS
              </a>
            </p>
          </div>
          <div>
            <br />
            <h4>I want to request a quote</h4>
            <p>
              Let us identify your needs so that we can provide you with the
              best solution. We would like to know more about you. We have our
              online sales page where you can request a quote and explore our
              products.
              <br />
              <a href="https://grupo-requiezshop.com/" target="_blank">
                STORE
              </a>
              <br />
              <br />
              Or contact us at the following email address:{" "}
              <a href="mailto:hola@gruporequiez.com">
                hola@gruporequiez.com
              </a>{" "}
              if you are an architect, designer, or if you have any special
              project. Let us know your needs, and one of our advisors will get
              in touch with you.
              <br />
            </p>
          </div>
          <div>
            <br />
            <h4>
              Can you provide the price for a product that is not on your
              website?
            </h4>
            <p>
              If you have a specific product in mind or if you didn't find it on
              our website, you can add an image and send us an email at{" "}
              <a href="mailto:hola@gruporequiez.com">hola@gruporequiez.com</a>{" "}
              and let us know about your special requirements. One of our
              advisors will get in touch with you.
              <br />
            </p>
          </div>
          <div>
            <br />
            <h4>How long will it take for my order to arrive?</h4>
            <p>
              Our response times are based on the specific characteristics of
              each order. If you have any questions about your order, you can
              directly contact your sales advisor or send us an email at{" "}
              <a href="mailto:hola@gruporequiez.com">hola@gruporequiez.com</a>{" "}
              and one of our advisors will get in touch with you.
            </p>
          </div>
          <div>
            <br />
            <h4>How can I purchase spare parts for a product I bought?</h4>
            <p>
              You can reach us via email at{" "}
              <a href="mailto:hola@gruporequiez.com">hola@gruporequiez.com</a>{" "}
              Please provide us with the details of your requirements, and one
              of our spare parts advisors will get in touch with you.
            </p>
          </div>
          <div>
            <br />
            <h4>How can I request a warranty?</h4>
            <p>
              All our products come with a warranty policy. In order to identify
              your warranty, we request you to send the information of your
              product to the email address{" "}
              <a href="mailto:hola@gruporequiez.com"> hola@gruporequiez.com</a>.
              One of our warranty department advisors will get in touch with
              you.
            </p>
          </div>
          <div>
            <br />
            <h4>What is your shipping coverage?</h4>
            <p>
              Our logistics and shipping area can deliver products to any state
              in the country. Please consult our terms and conditions. If you
              want to place an order or have any questions about our shipping
              coverage, you can contact us via email:{" "}
              <a href="mailto:hola@gruporequiez.com">hola@gruporequiez.com</a>{" "}
              and one of our advisors will get in touch with you.
            </p>
          </div>
          <div>
            <br />
            <h4>Can I buy online?</h4>
            <p>
              Yes, visit our{" "}
              <a href="https://grupo-requiezshop.com/">Online Store</a> and
              purchase our products 24/7.
            </p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Faq;
