import React, { useState } from "react";
import { useFormik } from "formik";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import Banner from "../../PagesComponents/Banner/Banner";
import axios from "axios";

import "./Faq.css";

import banner from "../../../assets/faqs.webp";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Faq = () => {
  return (
    <div className="faq">
      <Banner img={banner} />

      <section className="main">
        <h2>FAQS</h2>
        <div>
          <h4>¿Dónde puedo acudir a ver sus productos en físico?</h4>
          <p>
            Contamos con una gran red de distribución en todo el país, ingresa a
            nuestra siguiente pagina para que puedas identificar nuestro
            distribuidor autorizado mas cercano a tu localidad. CP. 45645.
            <br />
            <a href="https://www.requiez.com/distribuidores" target="_blank">
              DISTRIBUIDORES
            </a>
          </p>
        </div>
        <div>
          <br />
          <h4>Quiero realizar una cotización</h4>
          <p>
            Permítenos identificar cuáles son tus necesidades para brindarte la
            mejor solución, para ello, nos gustaría conocer un poco más sobre
            ti. Contamos con nuestra pagina en internet de venta en línea donde
            podrás cotizar y conocer nuestros productos.
            <br />
            <a href="https://tienda.gruporequiez.com/ " target="_blank">
              TIENDA
            </a>
            <br />
            <br />O contáctanos al siguiente correo:{" "}
            <a href="mailto:hola@gruporequiez.com">hola@gruporequiez.com</a> si
            eres arquitecto, proyectista o simplemente tienes algún proyecto
            especial, déjanos saber cual es tu necesidad y uno de nuestros
            asesores se pondrá en contacto contigo.
            <br />
          </p>
        </div>
        <div>
          <br />
          <h4>
            ¿Me pueden decir el precio de un producto que no esta en su página
            de internet?
          </h4>
          <p>
            Si tienes en mente algún producto con características específicas o
            no lo viste en nuestra página de internet, puedes agregar una imagen
            y enviarnos un correo a{" "}
            <a href="mailto:hola@gruporequiez.com">hola@gruporequiez.com</a> y
            déjanos saber cuál es tu necesidad especial, uno de nuestros
            asesores se pondrá en contacto contigo.
            <br />
          </p>
        </div>
        <div>
          <br />
          <h4>¿Cuánto tardará en llegar mi pedido?</h4>
          <p>
            Nuestros tiempos de respuesta se dan con base a las características
            específicas de cada pedido, si tienes alguna duda sobre tu pedido,
            puedes ponerte en contacto con tu asesor de ventas directamente, o
            envíanos un correo a{" "}
            <a href="mailto:hola@gruporequiez.com">hola@gruporequiez.com</a> y
            uno de nuestros asesores se pondrá en contacto contigo.
          </p>
        </div>
        <div>
          <br />
          <h4>
            ¿Como puedo comprar refacciones para algún producto que compre?
          </h4>
          <p>
            Ponemos a tu disposición nuestro correo electrónico{" "}
            <a href="mailto:hola@gruporequiez.com">hola@gruporequiez.com</a> te
            pedimos que nos envíes los datos de tus requerimientos y uno de
            nuestros asesores de refacciones se pondrá en contacto contigo.
          </p>
        </div>
        <div>
          <br />
          <h4>¿Como puedo solicitar una garantía? </h4>
          <p>
            Todos nuestros productos cuentan con una política de garantía, para
            poder identificar tu garantía, solicitamos que nos envíes la
            información de tu producto al correo electrónico{" "}
            <a href="mailto:hola@gruporequiez.com"> hola@gruporequiez.com</a> y
            uno de nuestros asesores de nuestro departamento de garantías se
            pondrá en contacto contigo.
          </p>
        </div>
        <div>
          <br />
          <h4>¿Cuál es su cobertura de envío? </h4>
          <p>
            Nuestra área de logística y envíos puede hacer llegar tus productos
            a cualquier estado de la república, consulta nuestros términos y
            condiciones, si deseas realizar un pedido o tienes alguna pregunta
            sobre nuestra cobertura de envío, puedes contactarnos al correo
            electrónico:{" "}
            <a href="mailto:hola@gruporequiez.com">hola@gruporequiez.com</a> y
            uno de nuestros asesores se pondrá en contacto contigo.
          </p>
        </div>
        <div>
          <br />
          <h4>¿Puedo comprar en línea?</h4>
          <p>
            Sí, visita nuestra{" "}
            <a href="https://grupo-requiezshop.com/ ">Tienda en Linea</a> y
            adquiere nuestros productos 24/7
          </p>
        </div>
      </section>
    </div>
  );
};

export default Faq;
