import React from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./BannerHome.css";

import img1 from "../../../assets/banner1.webp";
import img2 from "../../../assets/banner2.webp";
import img3 from "../../../assets/banner3.webp";
import img4 from "../../../assets/banner4.webp";


export default function BannerHome() {
  return (
    <Carousel fade controls={false} interval={3000}>
      <Carousel.Item>
        <a href="https://www.okamuramexico.com" target="_blank">
          <img
            className="d-block w-100 banner__image"
            src={img1}
            alt="First slide"
          />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 banner__image"
          src={img2}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 banner__image"
          src={img3}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <a href="/sustentability" >
          <img
            className="d-block w-100 banner__image"
            src={img4}
            alt="Fourth slide"
          />
        </a>
      </Carousel.Item>
    </Carousel>
  );
}
