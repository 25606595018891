import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Layout from "./Components/Pages/Layout/Layout";
import LayoutEn from "./Components/Pages/Layout/LayoutEn";
import Home from "./Components/Pages/Home/Home";
import HomeEn from "./Components/Pages/Home/HomeEn";
import ESR from "./Components/Pages/ESR/ESR";
import ESREn from "./Components/Pages/ESR/ESREn";
import History from "./Components/Pages/History/History";
import HistoryEn from "./Components/Pages/History/HistoryEn";
import Sustentability from "./Components/Pages/Sustentability/Sustentability";
import SustentabilityEn from "./Components/Pages/Sustentability/SustentabilityEn";
import Blog from "./Components/Pages/Blog/Blog";
import BlogEn from "./Components/Pages/Blog/BlogEn";
import BlogPost from "./Components/Pages/BlogPost/BlogPost";
import BlogPostEn from "./Components/Pages/BlogPost/BlogPostEn";
import Contact from "./Components/Pages/Contact/Contact";
import ContactEn from "./Components/Pages/Contact/ContactEn";
import WorkWithUs from "./Components/Pages/WorkWithUs/WorkWithUs";
import Faq from "./Components/Pages/Faq/Faq";
import FaqEn from "./Components/Pages/Faq/FaqEn";
import Lease from "./Components/Pages/Lease/Lease";




import BlogDataService from "./services/services";

function App() {
  const [user, setUser] = useState(
    !localStorage.getItem("user") ? null : localStorage.getItem("user")
  );
  const [token, setToken] = useState(
    !localStorage.getItem("token") ? null : localStorage.getItem("token")
  );
  const [error, setError] = useState("");

  const login = async (user = null) => {
    await BlogDataService.login(user)
      .then((response) => {
        setToken(response.data.token);
        setUser(user.username);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", user.username);
        setError("");
      })
      .catch((err) => {
        console.log("login", error);
        setError(err.toString());
      });
  };

  const logout = () => {
    setToken("");
    setUser("");
    localStorage.setItem("token", "");
    localStorage.setItem("user", "");
  };

  const signup = async (user = null) => {
    await BlogDataService.signup(user)
      .then((response) => {
        setToken(response.data.token);
        setUser(user.username);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", user.username);
      })
      .catch((err) => {
        console.log("signup", error);
        setError(err.toString());
      });
  };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Layout />} className="App">
            <Route
              index
              element={
                <div>
                  <Home />
                </div>
              }
            />
            <Route
              path="esr/"
              element={
                <div>
                  <ESR />
                </div>
              }
            />
            <Route
              path="history/"
              element={
                <div>
                  <History />
                </div>
              }
            />
            <Route
              path="sustentability/"
              element={
                <div>
                  <Sustentability />
                </div>
              }
            />
            <Route
              path="blog/"
              element={
                <div>
                  <Blog token={token} user={user} logout={logout} />
                </div>
              }
            />
            {/* <Route path="addpost/" element={<AddPost token={ token } user={ user } />} /> */}
            <Route
              path="blog/:id/"
              element={<BlogPost token={token} user={user} logout={logout} />}
            />
            <Route
              path="blogEn/:id/"
              element={<BlogPostEn token={token} user={user} logout={logout} />}
            />
            <Route
              path="contact/"
              element={
                <div>
                  <Contact />
                </div>
              }
            />
            <Route
              path="faq/"
              element={
                <div>
                  <Faq />
                </div>
              }
            />
            <Route
              path="lease/"
              element={
                <div>
                  <Lease />
                </div>
              }
            />
            <Route
              path="work/"
              element={
                <div>
                  <WorkWithUs />
                </div>
              }
            />
            {/* <Route path="login/" element={<Login login={ login } />} />
            <Route path="signup-rqz/" element={<Signup signup={ signup } />} /> */}
          </Route>
          <Route path="/" element={<LayoutEn />} className="App">
            <Route
              path="En-Us/"
              element={
                <div>
                  <HomeEn />
                </div>
              }
            />
            <Route
              path="En-Us/history"
              element={
                <div>
                  <HistoryEn />
                </div>
              }
            />
            <Route
              path="/En-us/sustentability/"
              element={
                <div>
                  <SustentabilityEn />
                </div>
              }
            />
            <Route
              path="/En-us/esr/"
              element={
                <div>
                  <ESREn />
                </div>
              }
            />
            <Route
              path="En-Us/blog"
              element={
                <div>
                  <BlogEn />
                </div>
              }
            />
            <Route
              path="En-Us/contact"
              element={
                <div>
                  <ContactEn />
                </div>
              }
            />
            <Route
              path="En-Us/faq"
              element={
                <div>
                  <FaqEn />
                </div>
              }
            />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
