import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import logo from "../../../assets/gr-logo.png";
import flag from "../../../assets/ES.png";

import "./Nav.css";

const Nav = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" className="navbar-bg">
      <Container maxWidth="xl" className="navbar-container">
        <Toolbar disableGutters className="">
          <img
            style={{ maxWidth: "200px" }}
            alt="Grupo Requiez - Logo"
            src={logo}
          />
          <Box
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            className="nav-links"
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              className="colorLetter"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <Link className="colorLetter" to="/">
                    HOME
                  </Link>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <HashLink className="colorLetter" to="/En-us/#brandsEn">
                    BRANDS
                  </HashLink>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <Link className="colorLetter" to="/En-us/history">
                    OUR HISTORY
                  </Link>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <Link className="colorLetter" to="/En-us/sustentability">
                    SUSTENTABILITY
                  </Link>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <Link className="colorLetter" to="/En-us/esr">
                    ESR
                  </Link>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <Link className="colorLetter" to="/En-us/blog">
                    BLOG
                  </Link>
                </Typography>
            </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <Link className="colorLetter" to="/En-us/contact">
                    CONTACT
                  </Link>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  <Link className="colorLetter" to="/lease">
                    LEASE
                  </Link>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography
                  textAlign="center"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Link className="colorLetter" to="/">
                    ES
                  </Link>
                  <img
                    src={flag}
                    alt="Flag"
                    style={{ marginLeft: "5px", width: "20px", height: "20px" }}
                  />
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Box
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
            className="nav-links"
          >
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
              component={Link}
              className="colorLetter"
              to="/En-us"
            >
              HOME
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
              component={HashLink}
              className="colorLetter"
              to="/En-us/#brandsEn"
            >
              BRANDS
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
              component={Link}
              className="colorLetter"
              to="/En-us/history"
            >
              OUR HISTORY
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
              component={Link}
              className="colorLetter"
              to="/En-us/sustentability"
            >
              SUSTENTABILITY
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
              component={Link}
              className="colorLetter"
              to="/En-us/esr"
            >
              ESR
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
              component={Link}
              className="colorLetter"
              to="/En-us/blog"
            >
              BLOG
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
              component={Link}
              className="colorLetter"
              to="/En-us/contact"
            >
              CONTACT
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
              component={Link}
              className="colorLetter"
              to="/lease"
            >
              LEASE
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block" }}
              component={Link}
              className="colorLetter"
              to="/"
              style={{ display: "flex", alignItems: "center" }}
            >
              ES
              <img
                src={flag}
                alt="Flag"
                style={{ marginLeft: "5px", width: "20px", height: "20px" }}
              />
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Nav;
