import React from "react";
import Banner from "../../PagesComponents/Banner/Banner";
import SpaceRight from "../../PagesComponents/SpaceRight/SpaceRight";
import Space from "../../PagesComponents/Space/Space";
import { Button } from "@mui/material";

import banner from "../../../assets/esr/bannerEn.webp";
import causas from "../../../assets/esr/esr_causas.webp";
import lazos from "../../../assets/esr/lazos.webp";
import healthy from "../../../assets/esr/healthy_life.webp";

import "./ESR.css";

export default function ESREn() {
  return (
    <div>
      <Banner img={banner} />
      <div className="esr-container">
        {/*<Button
          style={{
            width: "50%",

            top: "50px",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          href="https://gruporequiez.com/files/REPORTE_RSE.pdf"
          target="_blank"
          sx={{
            color: "var(--color-negro)",
            fontSize: "20px",
            fontWeight: "bold",
            borderColor: "var(--color-verdeoscuro)",
            alignContent: "center",
            textTransform: "capitalize",
            borderWidth: "4px",
            borderStyle: "solid",
          }}
          variant="outlined"
        >
          Discover our sustainability report.
        </Button>*/}
        <SpaceRight
          name="Social Causes"
          image={causas}
          info="Grupo Requiez actively collaborates in social causes with the aim of improving the quality of life for people in need. For example, we support therapy programs for children at the CRIT (Children's Rehabilitation Center) of Teletón."
          color="#FFF"
        />
        <Space
          name="Links"
          image={lazos}
          info="Partnership with Fundación Lazos for over 5 years, sponsoring 35 children who benefit from this project by receiving quality education with a sense of belonging focused on values and professional growth."
        />
        <SpaceRight
          name="Healthy Life"
          image={healthy}
          info="This program aims to promote and manage the emotional and physical well-being of employees. It encourages good health habits such as physical activity and a healthy diet, as well as monitoring blood pressure, glucose levels, vaccinations, and eye examinations."
          color="#FFF"
        />
      </div>
    </div>
  );
}
