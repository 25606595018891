import React, { useState } from "react";
import { Container, Form } from "react-bootstrap";

import "./ContactForm.css"; // Asegúrate de importar tu archivo de estilos

function ContactForm() {
  const [formData, setFormData] = useState({
    empresa: "",
    name: "",
    correo: "",
    telefono: "",
    ciudad: "",
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Realiza una solicitud POST a la API en modo "no-cors"
      const response = await fetch("https://pruebas-production-b73f.up.railway.app/api/send-email/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
    
      if (response.ok) {
        // Si la solicitud fue exitosa (código de respuesta 200), muestra el mensaje de éxito
        setSuccess(true);
        setError(null);
      } else {
        // Maneja el error aquí sin acceder al contenido de la respuesta
        setError("Hubo un error en la solicitud.");
        setSuccess(false);
      }
    } catch (err) {
      // Si hay un error en la solicitud, maneja el error aquí.
      // Puedes mostrar un mensaje de error o realizar otras acciones.
      setError("Hubo un error en la solicitud.");
      setSuccess(false);
    }
  }    

  return (
    <Container className="contact-form-container">
      <Container>
        <h1 className="text-center formTitle">Formulario de contacto</h1>

        {success && <div className="success-alert">Solicitud enviada!</div>}
        {error && <div className="error-alert">{error}</div>}

        <Form onSubmit={handleSubmit}>
          <div className="form-field text-center">
            <input
              type="text"
              id="empresa"
              name="empresa"
              value={formData.empresa}
              onChange={handleChange}
              placeholder="Empresa"
              required
              className="input"
            />
          </div>
          <div className="form-field text-center">
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Nombre"
              required
              className="input"
            />
          </div>
          <div className="form-field text-center">
            <input
              type="email"
              id="correo"
              name="correo"
              value={formData.correo}
              onChange={handleChange}
              placeholder="Correo"
              required
              className="input"
            />
          </div>
          <div className="form-field text-center">
            <input
              type="text"
              id="telefono"
              name="telefono"
              value={formData.telefono}
              onChange={handleChange}
              placeholder="Teléfono"
              required
              className="input"
            />
          </div>
          <div className="form-field text-center">
            <input
              type="text"
              id="ciudad"
              name="ciudad"
              value={formData.ciudad}
              onChange={handleChange}
              placeholder="Ciudad"
              required
              className="input"
            />
          </div>
          <a href="/files/aviso_privacidad_grupo_requiez.pdf"
            target="_blank"
            rel="noreferrer">
            Conoce nuestra política de Privacidad
          </a>

          
          <br />
          <br />
          <button type="submit" className="submit-button">
            Enviar Formulario
          </button>
        </Form>
      </Container>
    </Container>
  );
}

export default ContactForm;


